<template>
  <v-container>
    <v-row class="justify-center">
      <v-card width="400">
        <v-toolbar dense flat class="teal" dark>
          <v-toolbar-title>Cadastrar status de agendamento</v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <form>
            <v-text-field
              v-model="name"
              :error-messages="nameErrors"
              :counter="50"
              label="Nome do status"
              placeholder="...Executado"
              required
              outline
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
            <v-btn
              :disabled="$v.name.$invalid"
              class="mr-4"
              color="primary"
              outlined
              @click="submit"
            >
              Cadastrar
            </v-btn>
          </form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    name: { required }
  },

  data() {
    return {
      name: ""
    };
  },

  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Nome é um campo obrigatório!");
      return errors;
    }
  },

  methods: {
    async submit() {
      try {
        const response = await this.$http.post("/scheduling-status", {
          name: this.name
        });

        if (response) return this.$toast.success("Cadastrado com sucesso!");
      } catch (error) {
        if (error.response.status === 400)
          return this.$toast.error("Falha ao cadastrar, tente novamente!!!");
        return this.$toast.error("Tente novamente mais tarde!");
      }
    }
  }
};
</script>
<style></style>
